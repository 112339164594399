<template>
    <div>
      <div class="searchBox">
        <div class="searchLeft">
        </div>
        <div class="searchRight">
            <el-upload action="#" :auto-upload="false" :show-file-list="false" accept=".xlsx, .xls" :on-change="handleUserFile">
                <el-button
                    size="medium"
                    type="warning"
                    icon="el-icon-plus"
                    >上传用户模版</el-button>
            </el-upload>
            <el-button
              size="medium"
              type="warning"
              icon="el-icon-plus"
              @click="uploadImg('user')"
              >上传用户头像</el-button
            >
            <el-upload action="#" :auto-upload="false" :show-file-list="false" accept=".xlsx, .xls" :on-change="handleAlbumFile">
                <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-plus"
                    >上传相册模版</el-button>
            </el-upload>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="uploadImg('album')"
              >上传相册</el-button
            >
        </div>
      </div>
  
      <el-table border :data="tableData" height="76vh" style="width: 99%">
        <el-table-column prop="userId" label="用户Id"></el-table-column>
        <el-table-column prop="nickName" label="用户名"></el-table-column>
        <el-table-column prop="identifier" label="登录邮箱"></el-table-column>
        <el-table-column label="头像">
          <template slot-scope="scope">
            <img :src="scope.row.avatar" alt="头像" style="width: 50px; height: 50px;" v-if="scope.row.avatar.indexOf('http')>-1">
            <span v-else>{{scope.row.avatar}}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <span>{{`${scope.row.sex} ${scope.row.sex==1?'男':'女'}`}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column prop="height" label="身高"></el-table-column>
        <el-table-column prop="weight" label="体重"></el-table-column>
        <el-table-column prop="introduction" label="个人简介"></el-table-column>
      </el-table>
      <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
      <!-- 文件信息展示 -->
      <el-dialog  :close-on-click-modal="false" title="文件信息" :visible.sync="confirmVisible" width="800px" top="5vh">
        <el-table border :data="confirmTableData" height="70vh" style="width: 99%">
            <template v-if="uploadType==='user'">
              <el-table-column prop="userId" label="用户Id"></el-table-column>
              <el-table-column prop="nickName" label="用户名"></el-table-column>
              <el-table-column prop="identifier" label="登录邮箱"></el-table-column>
              <el-table-column prop="avatar" label="头像"></el-table-column>
              <el-table-column prop="sex" label="性别"></el-table-column>
              <el-table-column prop="age" label="年龄"></el-table-column>
              <el-table-column prop="height" label="身高"></el-table-column>
              <el-table-column prop="weight" label="体重"></el-table-column>
              <el-table-column prop="introduction" label="个人简介"></el-table-column>
            </template>
            <template v-if="uploadType==='album'">
              <el-table-column prop="identifier" label="登录邮箱"></el-table-column>
              <el-table-column prop="url" label="相册"></el-table-column>
            </template>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmVisible = false">取 消</el-button>
          <el-button type="primary" @click="uploadXlXSubmit">确定上传</el-button>
        </span>
      </el-dialog>
      <!-- 图片上传 -->
      <el-dialog  :close-on-click-modal="false" title="图片上传" :visible.sync="imgVisible" v-if="imgVisible" width="800px" top="5vh">
        <uploadImg :multiple="true" :limit="50" uploadApiUrl="/file/upload-new" :reqType="{ status:uploadType==='user'?2:1 ,type:'IMAGE'}"  @change="uploadImgChange"></uploadImg>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="imgVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import * as XLSX from 'xlsx';
  import uploadImg from '@/components/uploadImg'
  import pagination from '@/components/pagination'
  export default {
    data() {
      return {
        totalNum: 0,
        confirmVisible: false,
        imgVisible: false,
        searchForm: {
          pageNo: 1,
          pageSize: 10
        },
        tableData: [],
        confirmTableData: [],
        uploadType: 'user',//user:用户模版 album:相册模版
        rules: {
          blurRule: [
            { required: true, message: "请输入", trigger: "blur" },
          ],
          changeRule: [
            { required: true, message: "请选择", trigger: "change" },
          ],
          typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
        },
      };
    },
    components:{uploadImg,pagination},
    created() {
      this.getTableList();
    },
    methods: {
      uploadImg(type){
        this.uploadType = type;
        this.imgVisible = true;
      },
      // 处理文件选择
      handleUserFile(e) {
        const excelFile = e.raw;
        if (!excelFile) {
          this.$message.error('文件获取失败');
          return;
        }
        this.uploadType = 'user';
        this.readExcel(excelFile);
      },
      handleAlbumFile(e) {
        const excelFile = e.raw;
        if (!excelFile) {
          this.$message.error('文件获取失败');
          return;
        }
        this.uploadType = 'album';
        this.readExcel(excelFile);
      },
      handleData(data) {
        const headers = data[0].map(header => header.split('-')[0]);
        const result = data
          .slice(1)
          .filter(row => row.length > 0)
          .map(row => {
              const obj = {};
              headers.forEach((key, index) => {
                  obj[key] = row[index];
              });
              return obj;
          });
        return result;
      },
      readExcel(excelFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            console.log(worksheet)
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            console.log(jsonData)
            this.confirmTableData = this.handleData(jsonData);
            this.confirmVisible = true;
          } catch (error) {
            console.error('解析错误:', error);
            this.$message.error('文件解析失败: ' + error.message);
          }
        };

        reader.onerror = (error) => {
          this.$message.error('文件读取失败: ' + error.target.error.message);
        };
        reader.readAsArrayBuffer(excelFile);
      },
      async uploadXlXSubmit(){
        let res = null
        if(this.uploadType === 'user'){
          res = await this.$http.userTalkTemplateSaveRobotUser(this.confirmTableData)
        } else {
          res = await this.$http.userTalkTemplateSaveRobotAlbum(this.confirmTableData)
        }
        if(res){
          this.$message.success("上传成功！");
          this.confirmVisible=false;
          this.getTableList();
        }
      },
      uploadImgChange(val){
        this.getTableList()
      },
      async getTableList(val) {
        if(val) {
          this.searchForm.pageNo = 1
        }
        let res = await this.$http.userTalkTemplatePageRobotUser({...this.searchForm});
        if (res) {
          this.tableData = res.result.records;
          this.totalNum = res.result.totalCount;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  